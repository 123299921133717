/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410150058-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The list of all agent orchestrator names for agents accessible by the user.
 */
export interface AgentOrchestratorOutputV1 {
    /**
     * The ISO 8601 date when agent provisioning will expire (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "agentProvisioningExpirationAt"?: string;
    /**
     * The ISO 8601 date when agent provisioning was requested (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "agentProvisioningRequestedAt"?: string;
    /**
     * The provisioning status of the agent
     */
    "agentProvisioningStatus"?: AgentProvisioningStatusEnum;
    /**
     * The name of the agent orchestrator. This name uniquely identifies the agent orchestrator
     */
    "name": string;
}

export enum AgentProvisioningStatusEnum {
    PREPROVISIONED = 'PRE_PROVISIONED' as any,
    PROVISIONED = 'PROVISIONED' as any
}
